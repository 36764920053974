import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import ReactHtmlParser from 'react-html-parser';

const MIN_LENGTH_FOR_LONG_WORDS_IN_DESCRIPTION = 20;

const SectionDescriptionMaybe = props => {
  const { description, listingTitle, isCourse } = props;
  return description ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        {isCourse ? <FormattedMessage id="ListingPage.descriptionTitle" values={{ listingTitle }} /> : <FormattedMessage id="ListingPage.descriptionTitleJob" values={{ listingTitle }} /> }
      </h2>
      <p className={css.description}>
        {ReactHtmlParser(description)}
      </p>
    </div>
  ) : null;
};

export default SectionDescriptionMaybe;
